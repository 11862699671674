import { BToast } from "bootstrap-vue";
import i18n from "@/helpers/language";
import axios from "axios";
import moment from "moment";
import "moment/locale/sv";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    company: null,
    connected: true,
    pendingChanges: false,
    focusAreas: null,
    persons: null,
    departments: null,
    tags: null,
    esrstags: null,
    helpId: null,
    helpSearch: null
  },
  mutations: {
    setConnected: (state, value) => {
      state.connected = value;
    },
    setCompany: (state, company) => {
      state.company = company;
    },
    setPendingChanges: (state, value) => {
      state.pendingChanges = value;
    },
    setFocusAreas: (state, focusAreas) => {
      state.focusAreas = focusAreas;
    },
    setPersons: (state, persons) => {
      state.persons = persons;
    },
    setDepartments: (state, departments) => {
      state.departments = departments;
    },
    setTags: (state, tags) => {
      state.tags = tags;
    },
    setEsrsTags: (state, tags) => {
      state.esrstags = tags;
    },
    clearData: (state) => {
      state.focusAreas = null;
      state.persons = null;
      state.departments = null;
      state.tags = null;
      state.esrstags = null;
    },
    setHelpId: (state, id) => {
      state.helpId = id;
    },
    setHelpSearch: (state, text) => {
      state.helpSearch = text;
    },
  },
  actions: {
    showValidationError: (state, error) => {
      let errors = error.response.data;
      let message = [];
      let i = 0;
      let bootStrapToaster = new BToast();
      for (let [key, val] of Object.entries(errors)) {
        for (let i = 0; i < val.length; i++) {
          message[i] = "";
        }
        for (var index = 0; index < val.length; index++) {
          var error = val[index];
          if (typeof error == "string") {
            message[i++] += `${key}` + ": " + `${error}`;
          }
          else {
            if (!_.isEmpty(error)) {
              if (error.hasOwnProperty("text")) {
                message[i++] = `Index ${index + 1}: ` + error.text;
              }
              else {
                message[i++] = `Index ${index + 1}: Något gick fel!`;
              }
            }
            else {
            }
          }
        };
      }
      for (i = 0; i < message.length; i++) {
        bootStrapToaster.$bvToast.toast(message[i], {
          title: "Error",
          solid: true,
          appendToast: false,
          variant: "danger"
        });
      }
    },
    setCompany({ commit }, value) {
      commit("setCompany", value);
    },
    setFocusAreas({ commit }, value) {
      commit("setFocusAreas", value);
    },
    setPendingChanges({ commit }, value) {
      commit("setPendingChanges", value);
    },
    setFrontendLanguage({ commit }, value) {
      i18n.locale = value;

      moment.updateLocale("en", {
        week: {
          dow: 1 // Set week start to Monday for 'en' locale (defaults to Sunday, probably due to US)
        }
      });
      moment.weekdays(true); // Use locale-specific week start
      moment.locale(value);
    },
    setBackendLanguage({ commit }, value) {
      axios.defaults.headers.common["Accept-Language"] = value;
    },
    setPersons({ commit }, value) {
      commit("setPersons", value);
    },
    setDepartments({ commit }, value) {
      commit("setDepartments", value);
    },
    setTags({ commit }, value) {
      commit("setTags", value);
    },
    setEsrsTags({ commit }, value) {
      commit("setEsrsTags", value);
    },
    clearData({ commit }, value) {
      commit("clearData");
    },
    setHelpId({ commit }, value) {
      commit("setHelpId", value);
    },
    setHelpSearch({ commit }, value) {
      commit("setHelpSearch", value);
    },
  },
  getters: {
    company: (state) => {
      return state.company;
    },
    connected: (state) => {
      return state.connected;
    },
    pendingChanges: (state) => {
      return state.pendingChanges;
    },
    focusAreas: (state) => {
      return state.focusAreas;
    },
    persons: (state) => {
      return state.persons;
    },
    departments: (state) => {
      return state.departments;
    },
    tags: (state) => {
      return state.tags;
    },
    esrstags: (state) => {
      return state.esrstags;
    },
    helpId: (state) => {
      return state.helpId;
    },
    helpSearch: (state) => {
      return state.helpSearch;
    },
  }
};
